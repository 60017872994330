import React, { Component } from "react";
import { Button, Breadcrumb, notification } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { allApplications } from "../graphql/queries";
import { Application } from "../API";
import { client } from "../modules/API";
import { List } from "antd/lib/index";
import Link from "next/link";
import { gql } from "@apollo/client";
import structuredClone from "@ungap/structured-clone";
import { byString, byValue } from "sort-es";
import { rebuildDatabase } from "../graphql/mutations";
import {
    ArrowLeftOutlined,
    BuildOutlined,
    FolderAddOutlined,
    LoadingOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import Head from "next/head";
import { __APP_NAME__ } from "../settings";

export default class HomePage extends Component<any, IHomePageState> {
    constructor(props: any) {
        super(props);

        this.state = {
            applications: [],
            isRebuilding: false,
            loading: false
        };

        this.getApplications = this.getApplications.bind(this);
        this.rebuildDatabase = this.rebuildDatabase.bind(this);
    }

    componentDidMount(): void {
        this.getApplications();
    }

    getApplications(): void {
        this.setState({ loading: true });
        client
            .query({
                query: gql`
                    ${allApplications}
                `
            })
            .then((result: any) => {
                const applications: Array<Application> = structuredClone(
                    result.data.allApplications.items
                );
                if (applications && applications.length > 0) {
                    applications.sort(byValue((i) => i.name, byString()));
                }
                this.setState({ applications, loading: false });
            })
            .catch((result: any) => {
                this.setState({ loading: false });
                notification.error({
                    message: "Error retrieving info",
                    description: result.toString()
                });
            });
    }

    rebuildDatabase(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.setState({ isRebuilding: true });
            client
                .mutate({
                    mutation: gql`
                        ${rebuildDatabase}
                    `
                })
                .then(() => {
                    notification.success({
                        message: "Done"
                    });
                    this.setState({ isRebuilding: false });
                    resolve();
                })
                .catch((result: any) => {
                    notification.error({
                        message: "Error rebuilding",
                        description: result.toString()
                    });
                    this.setState({ isRebuilding: false });
                    reject(Error(result.toString()));
                });
        });
    }

    render(): JSX.Element {
        return (
            <>
                <Head>
                    <title>{__APP_NAME__}</title>
                </Head>
                <Breadcrumb style={{ margin: "16px 0" }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-content">
                    <PageHeader
                        ghost={false}
                        backIcon={
                            this.state.loading ? (
                                <LoadingOutlined />
                            ) : (
                                <ArrowLeftOutlined />
                            )
                        }
                        onBack={() => window.history.back()}
                        title="Applications"
                        subTitle=""
                        extra={[
                            <Button
                                key={1}
                                title="Reload"
                                onClick={this.getApplications}
                            >
                                <ReloadOutlined />
                            </Button>,
                            <Link key={2} href="/applications/new">
                                <Button title="New">
                                    <FolderAddOutlined />
                                </Button>
                            </Link>
                        ]}
                    >
                        <List>
                            {this.state.applications &&
                                this.state.applications.map(
                                    (application: Application) => (
                                        <List.Item key={application.id}>
                                            <Link
                                                href={
                                                    "/applications/" +
                                                    application.id
                                                }
                                            >
                                                {application.name}
                                            </Link>
                                        </List.Item>
                                    )
                                )}
                        </List>
                        <div style={{ marginTop: 50 }}>
                            <Button
                                title="Rebuild database"
                                loading={this.state.isRebuilding}
                                onClick={this.rebuildDatabase}
                            >
                                <BuildOutlined /> Rebuild database
                            </Button>
                        </div>
                    </PageHeader>
                </div>
            </>
        );
    }
}

interface IHomePageState {
    applications: Array<Application>;
    isRebuilding: boolean;
    loading: boolean;
}
