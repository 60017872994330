import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { __API__URL__ } from "../settings";

const cache = new InMemoryCache();
const httpLink = createHttpLink({
    uri: __API__URL__
});
const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const result: CognitoUserSession = await Auth.currentSession();
    const token = result.getAccessToken().getJwtToken();
    const newHeaders = {
        headers: {
            ...headers,
            authorization: token || ""
        }
    };
    return newHeaders;
});

export const client = new ApolloClient({
    // Provide required constructor fields
    cache,
    link: authLink.concat(httpLink)
});
