/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allApplications = /* GraphQL */ `
    query AllApplications($nextToken: String) {
        allApplications(nextToken: $nextToken) {
            items {
                id
                name
                active
                defaultRingId
                dateCreated
                dateModified
                __typename
            }
            nextToken
            count
            __typename
        }
    }
`;
export const getApplication = /* GraphQL */ `
    query GetApplication($applicationId: ID!) {
        getApplication(applicationId: $applicationId) {
            id
            name
            active
            defaultRingId
            dateCreated
            dateModified
            rings {
                id
                name
                active
                inactiveRedirectRingId
                updateBuildStatusNotifyOnBuildAgeTime {
                    seconds
                    timespan
                    relative
                }
                updateBuildStatusRequiredOnBuildAgeTime {
                    seconds
                    timespan
                    relative
                }
                updateBuildStatusForcedOnBuildAgeTime {
                    seconds
                    timespan
                    relative
                }
                dateCreated
                dateModified
                displayNames {
                    enUS
                    nlNL
                    deDE
                }
                fileName {
                    prefix
                    suffix
                }
                conditions {
                    type
                    key
                    value
                    not
                }
                __typename
            }
            changelog {
                userName
                userId
                dateCreated
                type
                changes
                __typename
            }
            __typename
        }
    }
`;
export const allVersions = /* GraphQL */ `
    query AllVersions($applicationId: ID!, $nextToken: String) {
        allVersions(applicationId: $applicationId, nextToken: $nextToken) {
            items {
                id
                name
                description
                state
                active
                releaseDate
                dateCreated
                dateModified
                searchIndex
                __typename
            }
            nextToken
            count
            __typename
        }
    }
`;
export const getVersion = /* GraphQL */ `
    query GetVersion($versionId: ID!) {
        getVersion(versionId: $versionId) {
            id
            application {
                id
                name
                active
                defaultRingId
                dateCreated
                dateModified
                __typename
            }
            name
            description
            state
            active
            releaseDate
            dateCreated
            dateModified
            changelog {
                userName
                userId
                dateCreated
                type
                changes
                __typename
            }
            searchIndex
            __typename
        }
    }
`;
export const allBuilds = /* GraphQL */ `
    query AllBuilds($applicationId: ID!, $versionId: ID!, $nextToken: String) {
        allBuilds(
            applicationId: $applicationId
            versionId: $versionId
            nextToken: $nextToken
        ) {
            items {
                id
                build
                active
                fullVersion
                fileName
                releaseDate
                dateCreated
                dateModified
                lastCronUpdate
                versionIndex
                __typename
            }
            nextToken
            count
            __typename
        }
    }
`;
export const getBuild = /* GraphQL */ `
    query GetBuild($buildId: ID!) {
        getBuild(buildId: $buildId) {
            id
            application {
                id
                name
                active
                defaultRingId
                dateCreated
                dateModified
                __typename
            }
            version {
                id
                name
                description
                state
                active
                releaseDate
                dateCreated
                dateModified
                searchIndex
                __typename
            }
            build
            active
            fullVersion
            fileName
            releaseDate
            changes {
                type
                title
                body
                __typename
            }
            rings {
                applicationId
                ring {
                    id
                    name
                }
                active
                fileName
                releaseDate
                notifyDate
                requiredDate
                forcedDate
                __typename
            }
            dateCreated
            dateModified
            lastCronUpdate
            changelog {
                userName
                userId
                dateCreated
                type
                changes
                __typename
            }
            ringData {
                nextToken
                count
                items {
                    id
                    ring {
                        id
                        name
                    }
                    toBuild {
                        id
                        fullVersion
                        version {
                            id
                            name
                        }
                    }
                    conditions {
                        not
                        type
                        key
                        value
                    }
                    status
                    override
                }
                __typename
            }
            versionIndex
            __typename
        }
    }
`;
export const allVersionOverview = /* GraphQL */ `
    query AllVersionOverview($nextToken: String) {
        allVersionOverview(nextToken: $nextToken) {
            items {
                ring {
                    id
                    name
                }
                fromBuild {
                    id
                    fullVersion
                    fileName
                }
                toBuild {
                    id
                    fullVersion
                    fileName
                    rings {
                        ring {
                            id
                        }
                        fileName
                    }
                }
                conditions {
                    not
                    type
                    key
                    value
                }
                id
                status
                override
                __typename
            }
            nextToken
            count
            __typename
        }
    }
`;
export const updateOverview = /* GraphQL */ `
    query UpdateOverview {
        updateOverview {
            id
            ring {
                id
                name
                active
                inactiveRedirectRingId
                dateCreated
                dateModified
                __typename
            }
            fromBuild {
                id
                build
                active
                fullVersion
                fileName
                releaseDate
                dateCreated
                dateModified
                lastCronUpdate
                versionIndex
                __typename
            }
            toBuild {
                id
                build
                active
                fullVersion
                fileName
                rings {
                    ring {
                        id
                    }
                    fileName
                }
                releaseDate
                dateCreated
                dateModified
                lastCronUpdate
                versionIndex
                __typename
            }
            conditions {
                type
                key
                value
                not
                __typename
            }
            status
            override
            __typename
        }
    }
`;
