/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addApplication = /* GraphQL */ `
    mutation AddApplication($application: InputApplication!) {
        addApplication(application: $application) {
            id
            name
            active
            defaultRingId
            dateCreated
            dateModified
            rings {
                id
                name
                active
                inactiveRedirectRingId
                dateCreated
                dateModified
                __typename
            }
            changelog {
                userName
                userId
                dateCreated
                type
                changes
                __typename
            }
            __typename
        }
    }
`;
export const editApplication = /* GraphQL */ `
    mutation EditApplication(
        $applicationId: ID!
        $application: InputApplication!
    ) {
        editApplication(
            applicationId: $applicationId
            application: $application
        ) {
            id
            name
            active
            defaultRingId
            dateCreated
            dateModified
            rings {
                id
                name
                active
                inactiveRedirectRingId
                dateCreated
                dateModified
                __typename
            }
            changelog {
                userName
                userId
                dateCreated
                type
                changes
                __typename
            }
            __typename
        }
    }
`;
export const deleteApplication = /* GraphQL */ `
    mutation DeleteApplication($applicationId: ID!) {
        deleteApplication(applicationId: $applicationId)
    }
`;
export const addVersion = /* GraphQL */ `
    mutation AddVersion($applicationId: ID!, $version: InputVersion!) {
        addVersion(applicationId: $applicationId, version: $version) {
            id
            application {
                id
                name
                active
                defaultRingId
                dateCreated
                dateModified
                __typename
            }
            name
            description
            state
            active
            releaseDate
            dateCreated
            dateModified
            changelog {
                userName
                userId
                dateCreated
                type
                changes
                __typename
            }
            searchIndex
            __typename
        }
    }
`;
export const editVersion = /* GraphQL */ `
    mutation EditVersion(
        $applicationId: ID!
        $versionId: ID!
        $version: InputVersion!
    ) {
        editVersion(
            applicationId: $applicationId
            versionId: $versionId
            version: $version
        ) {
            id
            application {
                id
                name
                active
                defaultRingId
                dateCreated
                dateModified
                __typename
            }
            name
            description
            state
            active
            releaseDate
            dateCreated
            dateModified
            changelog {
                userName
                userId
                dateCreated
                type
                changes
                __typename
            }
            searchIndex
            __typename
        }
    }
`;
export const deleteVersion = /* GraphQL */ `
    mutation DeleteVersion($applicationId: ID!, $versionId: ID!) {
        deleteVersion(applicationId: $applicationId, versionId: $versionId)
    }
`;
export const addBuild = /* GraphQL */ `
    mutation AddBuild(
        $applicationId: ID!
        $versionId: ID!
        $build: InputBuild!
    ) {
        addBuild(
            applicationId: $applicationId
            versionId: $versionId
            build: $build
        ) {
            id
            application {
                id
                name
                active
                defaultRingId
                dateCreated
                dateModified
                __typename
            }
            version {
                id
                name
                description
                state
                active
                releaseDate
                dateCreated
                dateModified
                searchIndex
                __typename
            }
            build
            active
            fullVersion
            fileName
            releaseDate
            changes {
                type
                title
                body
                __typename
            }
            rings {
                applicationId
                active
                fileName
                releaseDate
                notifyDate
                requiredDate
                forcedDate
                __typename
            }
            dateCreated
            dateModified
            lastCronUpdate
            changelog {
                userName
                userId
                dateCreated
                type
                changes
                __typename
            }
            ringData {
                nextToken
                count
                __typename
            }
            versionIndex
            __typename
        }
    }
`;
export const editBuild = /* GraphQL */ `
    mutation EditBuild(
        $applicationId: ID!
        $versionId: ID!
        $buildId: ID!
        $build: InputBuild!
    ) {
        editBuild(
            applicationId: $applicationId
            versionId: $versionId
            buildId: $buildId
            build: $build
        ) {
            id
            application {
                id
                name
                active
                defaultRingId
                dateCreated
                dateModified
                __typename
            }
            version {
                id
                name
                description
                state
                active
                releaseDate
                dateCreated
                dateModified
                searchIndex
                __typename
            }
            build
            active
            fullVersion
            fileName
            releaseDate
            changes {
                type
                title
                body
                __typename
            }
            rings {
                applicationId
                active
                fileName
                releaseDate
                notifyDate
                requiredDate
                forcedDate
                __typename
            }
            dateCreated
            dateModified
            lastCronUpdate
            changelog {
                userName
                userId
                dateCreated
                type
                changes
                __typename
            }
            ringData {
                nextToken
                count
                __typename
            }
            versionIndex
            __typename
        }
    }
`;
export const deleteBuild = /* GraphQL */ `
    mutation DeleteBuild($applicationId: ID!, $versionId: ID!, $buildId: ID!) {
        deleteBuild(
            applicationId: $applicationId
            versionId: $versionId
            buildId: $buildId
        )
    }
`;
export const ensureRingData = /* GraphQL */ `
    mutation EnsureRingData(
        $applicationId: ID!
        $versionId: ID!
        $buildId: ID!
        $ringDataId: ID
        $ringData: InputRingData
    ) {
        ensureRingData(
            applicationId: $applicationId
            versionId: $versionId
            buildId: $buildId
            ringDataId: $ringDataId
            ringData: $ringData
        ) {
            id
            ring {
                id
                name
                active
                inactiveRedirectRingId
                dateCreated
                dateModified
                __typename
            }
            fromBuild {
                id
                build
                active
                fullVersion
                fileName
                releaseDate
                dateCreated
                dateModified
                lastCronUpdate
                versionIndex
                __typename
            }
            toBuild {
                id
                build
                active
                fullVersion
                fileName
                releaseDate
                dateCreated
                dateModified
                lastCronUpdate
                versionIndex
                __typename
            }
            conditions {
                type
                key
                value
                not
                __typename
            }
            status
            override
            __typename
        }
    }
`;
export const deleteRingData = /* GraphQL */ `
    mutation DeleteRingData(
        $applicationId: ID!
        $versionId: ID!
        $buildId: ID!
        $ringDataId: ID!
    ) {
        deleteRingData(
            applicationId: $applicationId
            versionId: $versionId
            buildId: $buildId
            ringDataId: $ringDataId
        )
    }
`;
export const rebuildDatabase = /* GraphQL */ `
    mutation RebuildDatabase {
        rebuildDatabase
    }
`;
